<template>
  <div class="InnerPY_wrapper">
    <div class="title">
      <div
        class="title_con"
    
      >
        {{ dataList.propertyName }}
      </div>
    </div>
    <template>
      <div class="content" v-for="(item, index) in liabilitys" :key="index">
        <div class="con_header">
          <div class="header-con">{{ item.title }}</div>
          <div class="header-con">{{ item.amount }}</div>
        </div>
        <div class="con_detail">{{ item.content }}</div>
      </div>
    </template>
  </div>
</template> 

<script>
import "./PolicySchemeDetails.less";
import { getProductDetail } from "../../api/api";
export default {
  name: "PolicySchemeDetails",
  data() {
    return {
      liabilitys: [],
      dataList: [],
      currentIndex: 0, // 当前方案的索引，默认显示A方案
    };
  },
  methods: {
    async getData() {
      const  product  = await getProductDetail({
        pid: this.$route.query.productId,
        planCode: this.$route.query.planCode,
      });
      console.log(product);
      const  insurances  = JSON.parse(product.data.insurances)
      const dataList = insurances.insuranceAmount
      this.dataList = dataList
      this.liabilitys = dataList.liabilitys
    },
    // 切换方案
    toggleActive(index) {
      this.currentIndex = index;
      console.log(index)
      this.liabilitys = this.dataList[index].liabilitys
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style>
</style>